@import "../variables";
footer {
    padding: 30px 20px;
    text-align: center;
    background-color: $footer-background;
    font-size: 0.875rem;
    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 2000px;
        margin: auto;
        padding: 0 20px;
        &:not(.more-links) {
            font-size: 1rem !important;
            .left-footer {
                width: 100%;
                place-content: center;
                svg {
                    vertical-align: top;
                }
                @include respond(phone) {
                    i {
                        align-self: normal;
                    }
                    svg {
                        vertical-align: bottom;
                    }
                    p {
                        display: initial;
                    }
                }
            }
        }
    }
    .upper-footer {
        padding: 0 $layout-padding;
        p {
            font-weight: 400;
            font-size: 12px;
            color: $gray;
        }
        .funded {
            display: flex;
            justify-content: center;
            margin: 12px 8px;
            img {
                min-height: 18px;
                &:first-child {
                    margin-right: 17px;
                    width: 55px;
                    height: 17px;
                    align-self: center;
                }
                width: 96px;
                height: 18px;
            }
        }
    }
    .left-footer {
        display: inherit;
        i,
        p {
            align-self: center;
        }
        p {
            margin-left: 12px;
            display: flex;
            a {
                margin-left: 5px;
                text-decoration: underline;
                color: $primary;
                font-weight: bold;
            }
        }
        i {
            svg {
                width: 17px;
                height: 17px;
                vertical-align: bottom;
            }
        }
    }
    .lower-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        button {
            font-size: 0.875rem;
            text-decoration: underline;
            font-weight: bold;
            word-break: break-all;
            &:not(:last-of-type) {
                margin-right: 30px;
            }
        }
    }
    @include respond(tab-port-high) {
        .footer-container {
            flex-direction: column;
            .left-footer {
                padding-bottom: 0;
            }
            .lower-footer {
                padding-top: 30px;
            }
        }
    }
}
