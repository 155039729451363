@import "../variables";

.textbox-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    // &:first-of-type {
    //     margin-top: 0;
    // }

    label {
        font-family: $font-family-open-sans;
        margin-bottom: 8px;
        font-size: .875rem;
        color: #232323;
        span {
            font-weight: 300;
        }
        > p {
            margin-bottom: 0;
            font-weight: 300;
        }
    }
    .error-msg {
        font-weight: 400;
        color: $error;
        font-size: 1rem;
        margin-bottom: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.791504 16.6252H18.2082L9.49984 1.5835L0.791504 16.6252ZM10.2915 14.2502H8.70817V12.6668H10.2915V14.2502ZM10.2915 11.0835H8.70817V7.91683H10.2915V11.0835Z' fill='%23B30900'/%3E%3Crect x='8.7085' y='7.9165' width='1.58333' height='3.16667' fill='white'/%3E%3Crect x='8.7085' y='12.6665' width='1.58333' height='1.58333' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        padding-left: 24px;
    }
    .textbox-form-input {
        position: relative;

        select,input::-webkit-outer-spin-button,
        select,input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &.active-border {
            > input, select {
                border: 2px solid $primary;
            }
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        select, input {
            width: 100%;
            font-family: inherit;
            margin: 0;
            border: none;
            border: 2px solid $gray-light-3;
            border-radius: $primary-border-radius;
            position: relative;
            overflow: hidden;
            padding: 13px 45px 13px 12px;
            font-size: 16px;
            font-weight: 300;
            box-sizing: border-box;
            color:#3A3A3A;
            &::placeholder {
                color: $gray-light-6;
            }
            &:active, &:focus {
                border-color: $primary;
            }
        }

        .info-btn {
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%230066CC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9375 19.4624V18.8823C13.9375 18.0444 14.0664 17.3551 14.3242 16.8145C14.582 16.2738 15.0618 15.7026 15.7637 15.1011C16.7376 14.2775 17.3517 13.658 17.606 13.2427C17.8602 12.8273 17.9873 12.326 17.9873 11.7388C17.9873 11.0083 17.7528 10.4443 17.2837 10.0469C16.8146 9.64941 16.1397 9.45068 15.2588 9.45068C14.693 9.45068 14.1416 9.51693 13.6045 9.64941C13.0674 9.7819 12.4515 10.0236 11.7568 10.3745L11.123 8.92432C12.4766 8.21533 13.8909 7.86084 15.3662 7.86084C16.7341 7.86084 17.7975 8.19743 18.5566 8.87061C19.3158 9.54379 19.6953 10.4927 19.6953 11.7173C19.6953 12.2401 19.6255 12.7002 19.4858 13.0977C19.3462 13.4951 19.1403 13.8711 18.8682 14.2256C18.596 14.5801 18.0088 15.1512 17.1064 15.939C16.3831 16.5549 15.9051 17.0669 15.6724 17.4751C15.4396 17.8833 15.3232 18.4276 15.3232 19.1079V19.4624H13.9375ZM13.4111 22.6528C13.4111 21.6789 13.8408 21.1919 14.7002 21.1919C15.1156 21.1919 15.436 21.3172 15.6616 21.5679C15.8872 21.8185 16 22.1802 16 22.6528C16 23.1112 15.8854 23.4674 15.6562 23.7217C15.4271 23.9759 15.1084 24.103 14.7002 24.103C14.3278 24.103 14.0199 23.9902 13.7764 23.7646C13.5329 23.5391 13.4111 23.1685 13.4111 22.6528Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            position: absolute;
            top: 9px;
            right: 9px;
            width: 30px;
            height: 30px;
        }

        .arrow-icon {
            position: absolute;
            top: 12px;
            right: 8px;
            width: 30px;
            height: 30px;
            background-image: unset;
            background-repeat: unset;
        }

        &:not(.textbox-form-input-info) {
            input {
                padding: 9px 12px;
            }
        }

        &.textbox-form-input-password {
            input {
                padding: 13px 45px 13px 12px;
                &:focus {
                    + button {
                        svg {
                            path {
                                fill: $black !important;
                            }
                        }
                    }
                }
            }
            button {
                top: 16px;
                right: 16px;
                svg {
                    path {
                        fill: $black !important;
                    }
                }
            }
        }

        &.error {
            select, input {
                border-color: $error;
                &:active, &:focus {
                    border-color: $error;
                }
            }
        }

        &.error-input {
            select, input {
                border-color: $error;
                &:active, &:focus {
                    border-color: $error;
                }
            }
        }

        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
    }

    &.read-only {
        label {
            margin-bottom: 10px;
            font-size: 0.875rem;
        }
        input {
            border: none;
        }
        .info-btn {
            top: -15px;
        }
        .textbox-form-input-info {
            input {
                width: calc(100% - 45px);
            }
        }
    }

    ol {
        li {
            list-style: disc;
            margin-left: 24px;
            font-family: $font-family-open-sans;
            color: $black-light;
        }
    }
}

.textbox-form-container-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    @include respond(phone) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

.search-form {
    .search-form-input {
        > input {
            border: 1px solid $gray-light-3;
            border-radius: $primary-border-radius;
            width: 100%;
            overflow: hidden;
            padding: 13px;
            font-size: 16px;
            font-family: $font-family-open-sans;
            color: #112339;
        }
    }
}
