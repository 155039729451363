@import "../variables";

.checkbox-container {
    width: fit-content;
    .container {
        height: 100%;
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }

    .container input {
        opacity: 0;
        position: absolute;
        left: 0;
        &:focus {
            + .checkmark {
                border: 1px solid $primary !important;
            }
        }
    }

    .checkmark {
        border: 1px solid $gray-light-2;
        border-radius: 2px;
        position: absolute;
        height: 18px;
        width: 18px;
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
    }

    .container input:checked ~ .checkmark {
        background-color: $primary;
        border: 1px solid $primary;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    .container .checkmark:after {
        left: 6px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    p {
        font-weight: 400;
        font-family: $font-family-open-sans;
        font-size: 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15px;
    }
}
