@import "../variables";
.primary-btn {
  font-family: $font-family-open-sans;
  text-align: center;
  color: $white;
  background-color: $primary-blue;
  font-size: 1rem;
  border: 2px solid transparent;
  // min-width: 116px;
  padding: 10px 22px;
  border-radius: 10px;
  min-width: 54px;

  &.red-btn {
    background-color: #9d3427;
  }

  &.border-btn {
    border: 2px solid $primary-blue;
    background: transparent;
    color: $primary-blue;
    .lds-spinner div:after {
      background: $primary-blue;
    }
  }

  &.disabled {
    background-color: $gray-light-2;
    pointer-events: none;
  }
  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-bottom: -15px;
    div {
      transform-origin: center;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 5px;
        left: 9px;
        width: 2px;
        height: 6px;
        border-radius: 20%;
        background: white;
      }
    }
  }
  &.inverted {
    background: transparent;
    color: $primary;
    border: 2px solid $primary;
  }
  @include respond(medium) {
    width: fit-content;
  }
}

.button-container-2 {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 20px;
}
