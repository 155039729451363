.print-setup-instructions {
    max-width: 1200px;;
    width: calc(100% - 80px);
    padding: 40px;

    .box {
        background: #EBEBEB;
        border-radius: 4px;
        padding: 6px 12px;
        font-weight: 500;
    }

    .header {
        h1 {
            font-weight: 700;
            font-size: 2.25rem;
            margin-bottom: 36px;
        }

        .subtitle {
            display: flex;
            align-items: center;
            gap: 36px;
            font-size: 1rem;
        }
    }

    .content {
        margin-top: 36px;

        ul {
            margin-top: 24px;
            border-left: 3px solid #EBEBEB;
            padding-left: 24px;

            li {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 32px;
                display: flex;
                flex-direction: column;
                position: relative; 

                .stepIndex {
                    border-radius: 50%;
                    background: #000000;
                    color: #ffffff;
                    position: absolute;
                    top: -3px;
                    left: -40px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    font-weight: 700;
                }
                
                &:last-child {
                    margin-bottom: 0;
                }
                
                p {
                    font-size: 0.875rem;

                    &.box {
                        font-size: 1rem; 
                    }
                }

                &.step2 {
                    p {
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        p {
            a {
                display: inline-block;
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }

}

/* Print-Specific Styles */
@media print {
    .print-setup-instructions, 
    .print-setup-instructions .box, 
    .print-setup-instructions .stepIndex {
        -webkit-print-color-adjust: exact; /* Safari and Chrome */
        print-color-adjust: exact; /* Firefox */
    }
}