$primary: #0068e2;
$secondary: #4a4a4a;
$light-font: 300;
$medium-font: 400;
$heavy-font: 700;
$border: #d8d8d8;
$primary-background: #ffffff;
$primary-border-color: #000000;
$primary-border-radius: 8px;
$verified-me-background: #000b6f;
$layout-padding: 25px;
$warning: #b30900;
$valid: #385d0e;
$green: #1c7e66;
$error: #a03021;
$error-background: #f3e9e8;
$red: #ad4731;
// colors
$primary-blue: #005db8;
$dark-blue: #004fb3;
$black: #000000;
$black-dark: #473d66;
$black-dark-1: #091b31;
$black-dark-2: #001d39;
$black-light: #232323;
$black-light-1: #3d3d3d;
$white: #ffffff;
$gray: #4a4a4a;
$gray-light: #d8d8d8;
$gray-light-1: #6c6c6c;
$gray-light-2: #757575;
$gray-light-3: #e2e2e2;
$gray-light-4: #f2f2f2;
$gray-light-5: #454545;
$gray-light-6: #737373;
$gray-light-7: #e5e5e5;
$gray-light-8: #646464;
$gray-dark: #473d66;
$gray-dark-1: #333333;
$purple: #473d66;
$footer-background: #ededed;
$red-light: rgba(179, 9, 0, 0.15);

// font-family
$font-family: -apple-system, BlinkMacSystemFont, sans-serif;
$font-family-lato: "Lato", sans-serif;
$font-family-open-sans: "Open Sans", sans-serif;
$font-family-roboto: "Roboto", sans-serif;
$font-family-arial: "Arial", sans-serif;
$font-family-georgia: "Georgia", sans-serif;
// font weight
$regular: 400;
$bold: 700;
