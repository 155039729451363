@import "../variables";

.link-btn {
    color: $primary-blue;
    font-weight: $bold;
    font-size: 1.063rem;
    min-height: 22px;
    font-family: $font-family-open-sans;
    text-decoration: underline;
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: auto;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 2L2 8L8 14' stroke='%230068E2' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0 4px;
    padding-left: 18px;

    > svg {
        margin-right: 12px;
        width: 9px;
    }
    span {
        cursor: pointer;
    }
    &.decline {
        color: $warning;
    }
}