@import "./variables";

html {
    font-family: $font-family-open-sans;
    scroll-behavior: smooth;
    font-size: 1rem !important;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    min-width: 100vw;
    overflow-x: hidden;
}

@supports (font: -apple-system-body) {
    html {
        font: -apple-system-body, sans-serif;
    }
}

/* Remove outline for non-keyboard :focus */

button:focus:not(:focus-visible),
*:focus:not(.focus-visible) {
    outline: none;
}

/* Optional: Customize .focus-visible */

button:focus-visible,
.focus-visible {
    outline: 2px solid $primary !important;
}

#new-window {
    display: none;
}

.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */
}

input {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    box-sizing: border-box;
    border-radius: 0;
}

iframe {
    border: none !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-weight: 400;
}

h1 {
    margin-bottom: 18px;
    @include respond(phone) {
        font-size: 1.5rem;
        line-height: 1.3;
    }
}

h3 {
    font-weight: 400;
}

img {
    max-width: 100%;
    display: block;
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

button {
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: none;
}

a {
    display: block;
    text-decoration: none;
    color: #000;
}

p {
    line-height: 1.3;
}

hr {
    border: none;
}

.scroll-btns {
    cursor: pointer;
}

body {
    font-size: 1rem;
    line-height: 1.2 !important;
    background: #fff;
    -webkit-text-size-adjust: 100%;
}

.hidden {
    display: none;
    visibility: hidden;
}

.wrapper {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    padding: 38px;
    @include respond(phone) {
        padding: 16px;
    }
}

.brand-link {
    display: inline-block;
    margin-left: 4px;
    width: auto;
    color: $primary;
    border-bottom: 2px solid $primary;
}

.caretStyle {
    font-size: 1.25rem !important;
    color: $primary;
}

[class^="icon-"],
[class*=" icon-"] {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chevron:before {
    content: "\e900";
    color: rgb(227, 97, 97);
    font-size: 0.75rem;
}

.icon-error:before {
    content: "\e902";
    color: #e60b25;
    font-size: 1.5rem;
}

.sortIcon {
    font-size: 1rem;
    margin-left: 6px;
    color: $primary;
}

.caretUp {
    transform: rotate(180deg);
    margin-top: 6px;
}

.redirect-router-spinner-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner-wrapper {
        width: 100px;
        height: 100px;
        .spinner-container {
            transform: translateZ(0) scale(1);
        }
    }
}

.material-icons {
    /* Support for IE */
    font-feature-settings: "liga";
    display: inline;
}

.network-editor {
    .modal-body {
        text-align: center;
    }
    .disconnectButton {
        width: 250px;
        font-weight: 700;
    }
}

.sc-eNQAEJ {
    width: calc(100% - 32px);
}

.button--spinner-bg,
.button--spinner {
    .spinner-wrapper {
        width: 30px;
        height: 30px;
        .spinner-container {
            transform: translateZ(0) scale(0.35);
        }
    }
}

.button--spinner {
    .spinner-container div {
        background-color: #fff !important;
    }
}

.button--spinner-bg {
    padding: 4px 20px !important;
    .spinner-container div {
        background-color: $primary;
    }
}

.button--spinner-bg--white {
    .spinner-container div {
        background-color: #fff !important;
    }
}

.dots {
    font-size: 1.5rem !important;
}

.btn {
    padding: 8px 40px 10px;
    background-color: #4095d1;
    color: #fff;
    font-size: 1.125rem;
    border-radius: 19px;
    border-color: transparent;
}

.error-message {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    > p:first-child {
        margin-bottom: 12px;
    }
    > button {
        margin-top: 24px;
    }
}

.request-error-page {
    width: 100%;
    height: calc(100vh - 85px);
    display: flex;
    justify-content: center;
    align-items: center;
    .noResultErrorMessage {
        width: 100%;
        margin: 0;
        max-width: 550px;
        line-height: 1.3;
        flex-direction: column;
        > h3 {
            margin-bottom: 10px;
        }
        > p {
            margin-bottom: 36px;
        }
    }
}

.modal-wrapper {
    position: relative;
    .close-icon {
        position: absolute;
        top: -30px;
        right: -20px;
        cursor: pointer;
        > svg {
            font-size: 30px;
            color: #4a4a4a;
        }
    }
    .header {
        text-align: center;
        font-size: 1.875rem;
    }
    .modal-body {
        margin: 24px 0;
        line-height: 1.3;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        > p {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .sub-heading-top {
            margin-bottom: 12px;
            text-align: left;
            span {
                font-weight: 700;
            }
        }
        .sub-heading-bottom {
            text-align: left;
            font-weight: 400;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        @include respond(phone) {
            flex-direction: column;
        }
        button {
            border-radius: 150px;
            padding: 13px 20px;
            font-weight: 600;
            width: 48.5%;
            font-size: 1rem;
            @include respond(phone) {
                flex: 1;
                width: 100%;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }
        .okBtn {
            color: #fff;
            background-color: $primary;
        }
        .disconnectButton {
            color: #fff;
            font-size: 1rem;
            padding-top: 13px;
            padding-bottom: 13px;
            min-width: 175px;
            font-weight: 700;
            &:disabled {
                opacity: 0.3;
                cursor: inherit;
            }
        }
        .button--spinner {
            padding-top: 7px !important;
            padding-bottom: 6px !important;
        }
    }
}

.numeric {
    list-style-type: decimal;
}

.roman-numeric {
    list-style-type: lower-roman;
}

.alphabet {
    list-style-type: lower-latin;
}

strong {
    font-weight: 700;
}

.grecaptcha-badge {
    display: none !important;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.digit-textbox-6 {
    @include respond(xxSmall) {
        input {
            margin-right: 1rem !important;
        }
    }
    @supports (-webkit-overflow-scrolling: touch) {
        /* CSS specific to iOS devices */
        input, textarea {
            transform: translateZ(0px) !important;
        }
    }
}

.buttonLink { 

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: $primary-blue;

}

a.inline {
    display: inline;
}

.flash {
    background-color: black;
    opacity: 0;
    animation: snap 1s ease-out;
}
  
@keyframes snap {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.center-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}