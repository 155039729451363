.search-table {
  overflow-x: auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;

  .table-title {
    background-color: #2F5E8B;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    
    .title {
      padding: 8px 24px;
    }

  }

  .table-header-info {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    border-bottom: 1px solid #CCCCCC;


    .account-container > *{
      margin-bottom: 16px ;
      min-width: 150px;
    }


    .action-dropdown {
      position: 'absolute';
      margin: '16px 20px 0px 0px';
      background-color: 'white';
      border: '1px solid #ccc';
      border-radius: '5px';
      box-shadow: '0px 4px 6px rgba(0, 0, 0, 0.1)';
      z-index: 1000;
      right: '80px';

      .dropdown-item {
        padding: '10px 20px';
        cursor: 'pointer';
        border-bottom: '1px solid #f0f0f0';
        color: #3A3A3A;
      }

      .sensitive {
        color: #B30900,
      }
    }

    .action-button {
      position: 'relative';
      display: 'inline-block';
      width: 130px;
      min-width: 235px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      .input-action {
        display: flex;
        align-items: center;
        .icon {
          display: flex;
          margin-left: 4px;
        }
      }
    }
    
    .card {
      margin-top: 16px;
    }

    .status {
      display: inline-block;
      border: 1px solid #9c7308;
      border-radius: 50px;
      color: #9c7308;
      background: #fffde7;
      padding: 8px 14px;
      font-size: 0.875rem;
      font-weight: 600;

      &.verified,
      &.active {
        color: #43710f;
        border-color: #43710f;
        background: #e8f1dd;
      }

      &.unverified,
      &.inactive {
        color: #b30900;
        border-color: #b30900;
        background: #fff1f0;
      }

      &.placeholder {
        color: #9c7308;
        border-color: #9c7308;
        background: #fffde7;
      }

      &.pendingReview {
        color: #d16500;
        border-color: #d16500;
        background: #fffbf0;
      }
    }

    .nowrap {
      white-space: nowrap;
    }

    .title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-track {
    background: $primary;
    border-radius: 40px;
  }

  table {
    border-collapse: collapse;
    text-align: center;
    min-width: 100%;
    overflow: hidden;

    th,
    td {
      padding: 15px;
      min-width: 75px;
      text-align: left;

      &.nowrap {
        white-space: nowrap;
      }
    }
    .search-details-row {
      width: 100%;
      table-layout: fixed;

      & > td {
        padding: 0;
      }

      .search-Details {
        width: 100%;
        min-height: 110px;

        .no-details-error {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 110px;
        }

        .container {
          .content {
            .details-table {

              thead {
                tr {
                  background: #f5f5f5;


                  th {
                    border-right: 1px solid #CCCCCC;
                    padding: 10px 24px;
                    font-size: 1rem;
                    font-weight: 700;
                    color: #112339;
                    font-size: 14px;
                  }

                  th:last-child {
                    border-right: unset
                  }
                }
              }

              tbody {
                tr {
                  // &:nth-child(even) {
                  //   background-color: #f5f5f5;
                  // }


                  td {
                    border-top: 1px solid #CCCCCC;
                    border-right: 1px solid #CCCCCC;
                    padding: 8px 16px 6px 24px;
                    border-radius: 50px;
                    &.no-padding {
                      padding: 0;
                    }

                    button.item {
                      padding: 12px 24px;
                      height: 100%;
                      width: 100%;
                      position: relative;
                      input {
                        cursor: pointer;
                      }
                    }
                    
                    .desc {
                      min-width: 120px;
                    }
                    .edit {
                      color: #0066CC;
                      cursor: pointer;
                      font-size: 14px;
                      text-decoration: underline;
                    }

                    .item {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 6px;
                      width: 100%;
                      text-align: left;

                      > strong,
                      > div {
                        font-size: 0.875rem;
                        color: #112339;
                        flex: 1;
                      }

                      input {
                        color: #112339;
                        border: none;
                        width: auto;
                        min-width: auto;
                        background: transparent;
                        font-size: 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .status {
      display: inline-block;
      border: 1px solid #9c7308;
      border-radius: 50px;
      color: #9c7308;
      background: #fffde7;
      padding: 8px 14px;
      font-size: 0.875rem;
      font-weight: 600;

      &.verified,
      &.active {
        color: #43710f;
        border-color: #43710f;
        background: #e8f1dd;
      }

      &.unverified,
      &.inactive {
        color: #b30900;
        border-color: #b30900;
        background: #fff1f0;
      }

      &.placeholder {
        color: #9c7308;
        border-color: #9c7308;
        background: #fffde7;
      }

      &.pendingReview {
        color: #d16500;
        border-color: #d16500;
        background: #fffbf0;
      }
    }

    thead {
      background: #d1dbe5;

      p {
        font-weight: 700;
      }
    }

    tbody {
      color: #222222;
      font-weight: 500;
      border-radius: 0px 0px 10px 0px;

      .hide-row {
        display: none;
      }

      .empty-row-column {
        min-height: 35px;
      }

      td {
        font-size: 0.938rem;
        vertical-align: middle;

        &.last-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
        }
      }

      .empty-list {
        padding-left: 24px;
        width: 100%;
      }
    }
  }

}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  color: $gray-light-2;

  .actions {
    display: flex;
    cursor: pointer;
  }

  .btn-page {
    margin: 0px 16px;
  }
}

.search-table td.account-state {
  padding: 0;
  & > div {
    padding: 16px 32px;
    display: flex;
    gap: 10px;
    background-color: #fef2e6;
    color: #344054;
  }
}

.auto-width-input {
  position: relative;

  .hidden-span {
    position: absolute;
    visibility: hidden;
    white-space: pre;
    font: inherit;
  }
}
