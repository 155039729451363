.loginpage {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;

    @include respond(phone) {
        flex-direction: column;     
    }

    .sidebar {
        width: calc(100% - 60px);
        height: calc(100vh - 60px);
        max-width: 300px;
        padding: 30px;
        background: url("data:image/svg+xml,%3Csvg width='196' height='196' viewBox='0 0 196 196' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98 98C84.525 98 72.9896 93.2021 63.3937 83.6062C53.7979 74.0104 49 62.475 49 49C49 35.525 53.7979 23.9896 63.3937 14.3938C72.9896 4.79792 84.525 0 98 0C111.475 0 123.01 4.79792 132.606 14.3938C142.202 23.9896 147 35.525 147 49C147 62.475 142.202 74.0104 132.606 83.6062C123.01 93.2021 111.475 98 98 98ZM0 196V161.7C0 154.758 1.7885 148.38 5.3655 142.565C8.9425 136.751 13.6873 132.308 19.6 129.238C32.2583 122.908 45.1208 118.163 58.1875 115.003C71.2542 111.842 84.525 110.258 98 110.25C111.475 110.25 124.746 111.834 137.812 115.003C150.879 118.172 163.742 122.917 176.4 129.238C182.321 132.3 187.07 136.743 190.647 142.565C194.224 148.388 196.008 154.767 196 161.7V196H0Z' fill='%23021123'/%3E%3C/svg%3E%0A") no-repeat right calc(50% + 100px),
        url("data:image/svg+xml,%3Csvg width='271' height='374' viewBox='0 0 271 374' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M118 0L-35 68V170C-35 264.35 30.28 352.58 118 374C205.72 352.58 271 264.35 271 170V68L118 0ZM118 68C131.526 68 144.498 73.3732 154.062 82.9376C163.627 92.5019 169 105.474 169 119C169 132.526 163.627 145.498 154.062 155.062C144.498 164.627 131.526 170 118 170C104.474 170 91.5019 164.627 81.9375 155.062C72.3732 145.498 67 132.526 67 119C67 105.474 72.3732 92.5019 81.9375 82.9376C91.5019 73.3732 104.474 68 118 68ZM205.21 272C184.612 303.529 153.833 327.049 118 338.64C82.167 327.049 51.3876 303.529 30.79 272C25.01 263.5 20.08 255 16 245.99C16 217.94 62.07 194.99 118 194.99C173.93 194.99 220 217.43 220 245.99C215.92 255 210.99 263.5 205.21 272Z' fill='%23021123'/%3E%3C/svg%3E%0A") no-repeat left center, #112339;
        background-size: 40%, 60%, auto;
        color: #fff;
        
        @include respond(phone) {
            width: calc(100% - 60px);
            max-width: calc(100% - 60px);
            height: auto;
            background: #112339;
        }

        .logo {
            display: flex;
            flex-direction: column;

            >h1 {
                font-size: 2.5rem;
                margin-bottom: 2px;
            }

            >p {
                font-weight: 300;
            }
        }
    }

    > section {
        max-width: 1140px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;

        @include respond(phone) {
            margin-top: 54px;
        }

        .login-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            max-width: 550px;
            width: 100%;

            .header {
                width: 80%;
                position: relative;
                margin-bottom: 36px;

                @include respond(phone) {
                    width: 100%;
                }

                hr {
                    height: 2px;
                    background: $border;

                }

                > h3 {
                    font-size: 2rem;
                    font-weight: bold !important;
                    margin-bottom: 24px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: #fff;
                    padding: 0 12px;
                }
            }

            .btns-container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 12px;
                width: 100%;
                
                .login-btn {
                    border-radius: 10px;
                    max-width: 450px;
                    width: calc(100% - 48px);
                    color: #fff;
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
                    background: #3B69A1;
                    justify-content: center;
                    font-family: $font-family-open-sans;
                    padding: 15px 24px;
    
                    @include respond(phone) {
                        width: 100%;
                    }
            
                    &.google {
                        background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" fill="%234285F4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18.0009C11.4298 18.0009 13.467 17.195 14.9561 15.8205L12.0475 13.5623C11.2416 14.1023 10.2107 14.4214 8.99976 14.4214C6.65567 14.4214 4.67158 12.8382 3.96385 10.7109H0.957031V13.0428C2.43794 15.9841 5.48158 18.0009 8.99976 18.0009Z" fill="%2334A853"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7108C3.78409 10.1708 3.68182 9.59398 3.68182 9.0008C3.68182 8.40762 3.78409 7.8308 3.96409 7.2908V4.95898H0.957273C0.347727 6.17398 0 7.54853 0 9.0008C0 10.4531 0.347727 11.8276 0.957273 13.0426L3.96409 10.7108Z" fill="%23FBBC05"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="%23EA4335"/></svg>');
                        background-repeat: no-repeat;
                        background-position: 23px center;
                        background-size: 18px;
                    }
                
                    > span {
                        margin-left: 3px;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                }
            }
        }
    }
}