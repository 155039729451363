@import "../variables";
@import "../mixins";
.page {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh) * 100);
    position: relative;

    .back-btn {
        width: fit-content;
        margin-bottom: 36px;
    }
    &.footer {
        grid-template-rows: 1fr auto;
    }
    &.header {
        grid-template-rows: auto 1fr;
        &.footer {
            grid-template-rows: auto 1fr auto;
        }
    } 

    @include respond(medium) {
        header {
            .header-main {
                span {
                    font-size: 1.625em;
                }
                svg {
                    width: 41px;
                    height: 41px;
                }
            }
        }
        .login-passcode {
            h1 {
                font-size: 1.875em !important;
            }
        }
        &.wa-setup-login-form-layout {
            margin-top: 0 !important;
        }
        .wa-setup-header-m {
            display: none;
        }
        .wa-setup-header-d {
            display: block;
        }
    }
    @include respond(phone) {
        div[class*="account-settings"] {
            .link-btn {
                margin-bottom: 30px;
            }
        }
        .wa-setup-header-m {
            display: block;
        }
        .wa-setup-header-d {
            display: none;
        }
    }

}
