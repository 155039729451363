@import "../variables";
header {
    display: flex;
    flex-direction: column;
    background: #112339;
   
    .header-container {
        align-items: center;
        display: flex;
        width: calc(100% - 140px);
        max-width: 2000px;
        padding: 32px 70px;
        color: #fff;
        justify-content: space-between;
        margin: auto;

        @include respond(phone) {
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            padding: 16px;
            width: calc(100% - 32px);
        }

        h1 {
            font-size: 1.5rem;
            margin-bottom: 0;
        }

       .right-section {
            display: flex;
            gap: 12px;
            align-items: center;
            margin-left: auto;

            @include respond(phone) {
                margin-left: 0;
                margin-top: 24px;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
            }

            > p {
                color: #fff;
                display: flex;
                align-items: center;
                font-size: 1rem;
                font-weight: 500;
                text-transform: capitalize;

                > svg {
                    margin-right: 6px;
                }
            }

            .logout {
                font-size: 0.875rem;
                color: #fff;
                background-image: none;
                font-weight: 300;
                text-decoration: none;
                padding-left: 0;
            }
       }
    }
}
