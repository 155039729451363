.form-1 {
    .set {
        margin-top: 24px;

        .title {
            font-weight: 700;
            font-size: 1.125rem;
            margin-bottom: 14px;
        }

        .radiobutton-container {
            margin-bottom: 10px;
            display: flex;

            > input {
                margin: 0 10px 0 0;
                cursor: pointer;
            }

            > label {
                font-weight: 300;
                color: #222222;
                cursor: pointer;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .checkbox-container {
            margin-bottom: 12px;
            display: flex;
            
            > input {
                margin: 0 10px 0 0;
                cursor: pointer;
            }

            > label {
                color: #222222;
                cursor: pointer;

                > p {
                    font-weight: 300;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .primary-btn {
        margin-top: 24px;
        min-width: 222px;
    }
}